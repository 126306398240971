import React, { useState } from "react";

import PortfolioCard from "./PortfolioCard";

import "./Portfolio.css";

function Portfolio() {
  const [expandedCard, setExpandedCard] = useState(null);

  return (
    <div id='portfolio'>
      <h1>Portfolio</h1>
      <div className='text-container'>
        <p>A few personal projects I've worked on in my spare time.</p>
      </div>
      <div className='portfolio-container'>
        <PortfolioCard
          title='PresentPicker.ai'
          link='https://presentpicker.ai'
          cardNumber={1}
          expandedCard={expandedCard}
          setExpandedCard={setExpandedCard}
        >
          <img src='presentpickerdemo.gif' alt='Present Picker AI Demo' />
          <p>
            A full-stack Next.js application that uses a Llama-3 model to
            generate personalized gift recommendations for users based on
            information they provide about the recipient, the occasion, etc.
            Gift recommendations contain affiliate links to products on
            3rd-party stores.
          </p>
          <h5>May, 2024</h5>
        </PortfolioCard>
        <PortfolioCard
          title='Custom Trading Client'
          cardNumber={2}
          expandedCard={expandedCard}
          setExpandedCard={setExpandedCard}
        >
          <img src='tradingclient.gif' alt='Trading Client Demo' />
          <p>
            A Windows-based futures trading client built with C#. Executes
            orders and fetches market data through Interactive Brokers' TWS API.
            Automatically stores and retrieves analytics to and from an SQL
            database. Loads tradingview.com in a .NET webView for seemless
            charting access.
          </p>
          <h5>November, 2022</h5>
        </PortfolioCard>
        <PortfolioCard
          title='This Website'
          cardNumber={3}
          expandedCard={expandedCard}
          setExpandedCard={setExpandedCard}
        >
          <img src='earthmodel.gif' alt='3D Earth Model Demo' />
          <p>
            A simple React.js website that uses React Three Fiber (a Three.js
            wrapper) for 3D effects.
          </p>
          <h5>December, 2023</h5>
        </PortfolioCard>
      </div>
    </div>
  );
}

export default Portfolio;
